<template>
	<div id="container">
		<div id="map"></div>
		<button v-if="mode !== 'review'" @click="switchMode('review')">📖 Review Mode</button>
		<button @click="switchMode('quiz')">{{mode === 'review' ? '⭐️ Begin' : "💫 Reset"}} Quiz</button>
		<h1 :class='{feedback: true, hidden: isFeedbackHidden}'>{{feedback}}</h1>
			<h2 v-if="mode === 'review' && !selectedRegionId">Hover anywhere to study that region.</h2>
			<h2 v-if="mode === 'review' && !selectedRegionId">Press "Begin Quiz" when you are ready.</h2>
		<div v-if="mode==='review' && selectedRegionId">
			<h2>{{selectedRegion.simplified}}</h2>
			<h2>{{selectedRegion.pinyin}}</h2>
			<h2>{{selectedRegion.english}}</h2>
		</div>
		<div v-if="mode ==='quiz'">
			<h2>Question {{quizIndex+1}}/{{quizList.length}}: Please click on</h2>
			<h2>{{expectedRegion.simplified}}</h2>
		</div>
		<div v-if="mode ==='quiz-over'">
			<h2>{{quizCorrect}} / {{quizIndex}} Correct {{award}}</h2>
			<h2 v-if="quizCorrect !== quizIndex">Press "Review Mode" to study some more!</h2>
			<h2>{{quizCorrect !== quizIndex ? `Or if you're ready, press ` : 'Press'}} "Reset Quiz" to go again!</h2>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
/**
 *	TODO
 * 
 * 1. Add other maps or work on branding etc? Basically, get ready to publish as is
 * 2. Option to use
 * 	english names (long/short/none)
 * 	pinyin names (long/short/none)
 * 	chinese names (long/short/none)

 * 3. Option to include disputed territories
 * 
 */
// 
import * as topojson from "topojson-client";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const mapTopo = require('../assets/topojson/countries/china/china-provinces.json')
const topoObjName = Object.keys(mapTopo.objects)[0];
const geojson = topojson.feature(mapTopo, topoObjName);

const styles = {}
styles.default = {
	fillColor: 'white',
	weight: 1,
	color: 'steelblue'
}
styles.hover = {
	'fillColor': 'steelblue',
}

function extractData(data){
	const splitZh = data.NL_NAME_1.split('|')
	const traditional = splitZh[0]
	const simplified = splitZh[splitZh.length -1]
	
	let english;
	let pinyin;
	if(data.VARNAME_1.includes('|'))
		[english, pinyin] = data.VARNAME_1.split('|')
	else
		[english, pinyin] = [data.NAME_1, data.VARNAME_1]
	english += (' ' + data.ENGTYPE_1)
	pinyin += (simplified.includes('自治区') ? ` ${data.TYPE_1}` : `` )

	const id = data.ID_1 + ''
	
	return { simplified, traditional, pinyin, english, id };
}

function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}

export default {
	data(){
		return {
			geojson,
			width: 1000,
			height: 700,
			allRegionsData: {},
			selectedRegionId: null,
			expectedRegionId: null,
			quizList: [],
			quizIndex: 0,
			quizCorrect: 0,
			mode: 'review',
			feedback: '🇨🇳',
			isFeedbackHidden: false
		}
	},
	computed: {
		expectedRegion(){
			return this.allRegionsData[(this.expectedRegionId)]
		},
		selectedRegion(){
			return this.allRegionsData[(this.selectedRegionId)]
		},
		award(){
			const dict = {
				0: "🥇",
				1: "🥈",
				2: "🥉",
			}
			const awardKey = this.quizIndex - this.quizCorrect;
			return awardKey in dict? dict[awardKey] : '💩'
		}
	},
	mounted() {
		this.makeMap()
	},
	methods: {
		markFailures(){
			this.forEachRegion(function(region){
				if(region.isStyleLocked === false){
					region.setStyle({'fillColor' : 'red'})
					region.isStyleLocked = true;
				}
			})
		},
		forEachRegion(fn){
			for(const key in this.allRegionsData){
				fn(this.allRegionsData[key])
			}
		},
		switchMode(mode){
			this.forEachRegion(region => {
				region.setStyle(styles.default)
				region.isStyleLocked = false
			})
			if(mode === 'quiz'){
				this.onGameReset()
			}
			this.mode = mode
			document.querySelector('.feedback').focus()
		},
		makeMap() {
			const zoomLevel = 4.3
			const map = L.map("map", {
				attributionControl: false,
				zoomControl: false,
				doubleClickZoom: false,
				dragging: false,
				minZoom: zoomLevel,
				maxZoom: zoomLevel,
			}).setView([0,0]);
			var layer = new L.geoJSON(this.geojson, {
				style: styles.default,
				onEachFeature: this.onEachFeature
			}).addTo(map);
			map.fitBounds(layer.getBounds());
		},
		onEachFeature({properties}, layer){
			const v = this
			const extractedData = extractData(properties)
			const {id} = extractedData
			this.allRegionsData[id] = {
				...extractedData,
				setStyle: layer.setStyle.bind(layer),
				isStyleLocked: false,
			}
			const region = this.allRegionsData[id]
			layer.on('click', function(){
				if(v.mode !== 'quiz'){
					console.log('TODO bring up wiki page or something?')
					return
				}
				v.feedback = ''
				v.isFeedbackHidden = true
				if(id === v.expectedRegionId){
					region.isStyleLocked = true
					this.setStyle({fillColor: 'green'})
					v.quizCorrect += 1
					v.feedback = '🥳'
				}
				else {
					v.feedback = '😭'
				}
				v.isFeedbackHidden = false
				v.quizIndex += 1
				if(v.quizIndex >= v.quizList.length){
					v.expectedRegionId = null
					v.feedback = '🇨🇳'
					v.markFailures()
					v.mode = 'quiz-over'
					return
				}
				setTimeout(()=>{
					v.isFeedbackHidden = true
				}, 1000)
				v.expectedRegionId = v.quizList[v.quizIndex]
			})
			layer.on('mouseover', function () {
				v.selectedRegionId = id
				if(!region.isStyleLocked)
					this.setStyle(styles.hover);
    	});
			layer.on('mouseout', function () {
				v.selectedRegionId = null
				if(!region.isStyleLocked)
					this.setStyle(styles.default)
			});
		},
		onGameReset(){
			this.quizList = shuffle(Object.keys(this.allRegionsData))
			this.expectedRegionId = this.quizList[0]
			this.quizIndex = 0
			this.quizCorrect = 0
			this.mode = 'quiz'
		},
	}
}
</script>


<style>
	button {
		background-color: steelblue;
		font-family: inherit; /* 1 */
		font-size: 1.2rem;
		border-radius: 10px;
		padding: 5px;
		width: 200px;
		color: white;
		margin-right: 2.5px;
		border: 1px solid steelblue;
  }
	button + button {
		margin-right: 0;
		margin-left: 2.5px;
	}
	button:hover {
		border: 1px solid steelblue;
		background-color: white;
		color: steelblue;
	}
	.leaflet-container {
			background-color:rgba(255,0,0,0.0);
	}
	#map {
		height: 100vh;
		max-height: min(700px, 100vw);
		width: 100vw;
		max-width: min(1000px, 100vh);
	}
	h2 {
		color: steelblue;
	}
	.feedback {
		visibility: visible;
		opacity: 1;
		/* transition: opacity 2s linear; */
	}
	.feedback.hidden {
		visibility: hidden;
		opacity: 0;
		transition: visibility 0s 2s, opacity 2s linear;
	}
</style>