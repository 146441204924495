<template>
  <div class="home">
    <HoverMap/>
  </div>
</template>

<script>
// @ is an alias to /src
import HoverMap from '@/components/HoverMap.vue'

export default {
  name: 'Home',
  components: {
    HoverMap
  }
}
</script>