import { createRouter, createWebHistory } from 'vue-router'
import MapQuiz from '../views/MapQuiz.vue'

const routes = [
  {
    path: '/',
		alias: ['/maps/china'],
		name: 'Map Quiz',
    component: MapQuiz
	},
	{
		path: '/maps',
		name: "All Maps",
		component: () => import(/* webpackChunkName: "about" */ '../views/MapsList.vue')
	},
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
